@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

* {
    list-style: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font-family: "Roboto", sans-serif;
    margin: 0;
    padding: 0;
    border: 0;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.page {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    font: 19px "Roboto", sans-serif;
    text-align: center;
    line-height: 1.4;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

    @media screen and (min-width: 451px) {
        font-size: 22px;
    }

    @media screen and (min-width: 1025px) {
        font-size: 25px;
    }

    p {
        margin: 0;
    }

    span {
        width: 50px;
        height: 50px;
        border-width: 4px;
        border-style: solid;

        @media screen and (min-width: 451px) {
            width: 70px;
            height: 70px;
        }

        @media screen and (min-width: 1025px) {
            width: 100px;
            height: 100px;
        }
    }
}